import React from 'react';
import { InternationalPhoneInputWrapper, InternationalPhoneInputStyled, InputWrapper } from './InternationalPhoneInputStyled';
import PhoneInput from 'react-phone-number-input';
import { useField } from 'formik';
import {FiSearch} from 'react-icons/fi';
import { Error } from '../Error';

export const InternationalPhoneInput = ({placeholder,icon,fullWidth , handleChange, customHandle, name, label, ...props}) => {
    const meta = useField(name)[1];

    return (
        <InternationalPhoneInputWrapper>
            {
                label ? <label htmlFor={name}>{label}</label> : null
            }
            <InternationalPhoneInputStyled fullWidth={fullWidth}>
                <InputWrapper>
                    { icon && 
                    <span><FiSearch/></span>
                    }
                    <PhoneInput
                        placeholder={placeholder}
                        type="Phone" 
                        name={name}
                        international={true}
                        defaultCountry='AU'
                        onChange={customHandle? (e)=>customHandle(e.target.value) : handleChange} 
                    />
                </InputWrapper>
                <Error meta={meta} />
            </InternationalPhoneInputStyled>
        </InternationalPhoneInputWrapper>
    );
};
