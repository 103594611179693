import styled from 'styled-components';
import {theme} from '../../theme';
export const NavbarWrapper =  styled.div`

        .top-bar {
          transform: rotate(45deg)!important;
          transform-origin: -7% 275%;
        }
        .middle-bar {
          display:none !important;
        }
        .bottom-bar {
          transform: rotate(-45deg)!important;
          transform-origin: 0% -140%;
        }
        .Sidebar-Heading {
          font-size:19px;
          font-family:${theme.fonts.Replica_Regular};
        }
        .pro-sidebar .pro-menu {
            background-color: ${theme.colors.background_white};
            overflow-y: auto;
            height: calc(90vh - 76px);
            padding-top: 32px;
            padding-bottom: 16px;
        }
        }
        #header .closemenu {
          color: #000;
          position: relative;
          right: 0;
          z-index: 9999;
          line-height: 20px;
          border-radius: 50%;
          font-weight: bold;
          font-size: 22px;
          top: 55px;
          cursor: pointer;
        }
        #header .pro-sidebar .pro-menu .pro-menu-item {
          margin-bottom: 16px;
        }
        #header .pro-sidebar .pro-menu .pro-inner-item {
          padding: 0;
        }
        #header .pro-sidebar .pro-menu .pro-inner-item .pro-item-content {
          display: flex;
          text-transform: none;

          a {
            display: flex;
            width: 100%;
            padding: 20px;
            background: ${theme.colors.background_tint_light};
            border-radius: 8px;
            font-size: 14px;
          }
        }
        #header .pro-sidebar .pro-menu .sidemenu {
          .pro-inner-item .pro-item-content {
            a {
              display: inline;
              background: none;
              padding: 8px 0;
              font-family: ${theme.fonts.Inter_regular};
            }
          }

          &.secondary {
            padding-top: 8px;

            .pro-inner-item .pro-item-content {
              a {
                display: inline-flex;
                align-items: center;
                background: none;
                padding: 0;
                font-family: ${theme.fonts.Inter_semibold};

                .icon {
                  display: flex;
                  width: 16px;
                  height: 16px;
                  margin-right: 8px;

                  svg {
                    path {
                      fill: ${theme.colors.brand_primary_blue};
                    }
                  }
                }
              }
            }
          }
        }
        #header .pro-sidebar .pro-menu a {
          text-decoration: none;
          color: ${theme.colors.type_dark_heading};
          font-family: ${theme.fonts.Inter_medium};
        }
        #header .pro-sidebar {
          display: block;
          position: fixed;
          top: 72px;
          right: 0;
          bottom: 0;
          width: 300px;
          min-height: 100vh;
          padding: 0 0 16px;
          background-color: ${theme.colors.background_white};
          border-left: 1px solid #dbdada;
          transition: all .4s cubic-bezier(.7,0,.3,1);
          overflow-y: auto;
          z-index: 899;
        }
        #header .pro-sidebar.collapsed {
          transition: all .4s cubic-bezier(.7,0,.3,1);
          right: -101vw;
          border-left: none;
          z-index:-1;
        }
        #header .pro-sidebar-inner {
          background-color: white;
          box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
        }
        #header .pro-sidebar-inner .pro-sidebar-layout {
          overflow-y: hidden;
        }
        #header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
          font-size: 20px;
          padding: 0 20px;
          color: #000;
          font-weight: bold;
        }
        #header .pro-sidebar-inner .pro-sidebar-layout ul {
          padding: 0 16px;
        }
        #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {

          display: block;
          color: #313131;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 12px;
          font-family:${theme.fonts.Replica_Regular};

        }
        #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
          color: #000;
          border-radius: 3px;
        }
        #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
          color: #000;
        }
        #header .pro-sidebar-inner .pro-sidebar-layout .active {
          background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
        }
        #header .logo {
          padding: 20px;
        }

        @media only screen and (max-width: 720px) {
          html {
            overflow: hidden;
          }
        }
    .navbar{
        position: fixed;
        right: 0;
        left: 0;
        z-index:899;

        &.navbar-primary {
          top: 0;

          ${theme.mediaQueries.xxl} {
            top: 48px;
          }
        }

        ${theme.mediaQueriesMaxWidth.xxl} {
          padding: 0;
        }
    }
    nav{  
        background-color : ${theme.colors.background_white};
        min-height: 88px;
        border-bottom: 1px solid ${theme.colors.background_tint_dark};
        @media screen and (min-width: 1440px) {
          .container {
            max-width: 1440px;
          }
        }
        .navbar-brand {
          margin-right: 0;
          color : ${theme.colors.white} !important;
        }
        .navbar-toggle {
          display: block;
        }
        .nav-link {
            color : ${theme.colors.type_dark_heading};
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
            font-family: ${theme.fonts.Inter_medium};
            padding: 16px 20px;
            border-radius: 9999px;
            transition: all .4s cubic-bezier(.7,0,.3,1);
            
            &:hover {
              background: ${theme.colors.background_accent};
              color: ${theme.colors.background_dark_blue};
            }
          }
        .navbar {
          .navbar-collapse{
              height:100px;
          }
        }
        &.navbar-secondary {
          min-height: 48px;
          background-color: ${theme.colors.brand_primary_blue};

          .left-menu {
            gap: 40px;
          }

          .right-menu {
            gap: 32px;
          }

          .nav-link {
            position: relative;
            display: flex;
            align-items: center;
            padding: 0;
            color: ${theme.colors.white};
            font-family: ${theme.fonts.Inter_regular};

            &:before {
              content: "";
              transform-origin: 100% 50%;
              transform: scale3d(0, 1, 1);
              transition: transform .3s;
              position: absolute;
              width: 100%;
              height: 1px;
              background: #fff;
              bottom: -5px;
              left: 0;
              pointer-events: none;
            }

            .icon {
              display: flex;
              width: 16px;
              height: 16px;
              margin-right: 16px;
            }

            &:hover {
              background: none;

              &:before {
                transform-origin: 0% 50%;
                transform: scaleZ(1);
              }
            }
          }
        }
        &.floating-content {
          position: fixed;
          bottom: 0;
          width: 100%;
          padding: 0 20px;
          display: flex;
          align-items: center;

          .contact-button {
            width: 100%;
            padding: 0;
            height: 48px;
            color: ${theme.colors.white};
            background: ${theme.colors.brand_secondary_blue};
          }
        }
        .logo {
          display: block;
          width: 89px;
          height: 24px;
          background: url(/Images/logo-reskinned.svg) 0 0/89px 24px;
          float: left;

          ${theme.mediaQueries.xxl} {
            width: 102px;
            height: 28px;
            background: url(/Images/logo-reskinned.svg) 0 0/102px 28px;
          }

          @media (max-width: 360px) {
            width: 60px;
            height: 18px;
            background: url(/Images/logo-reskinned.svg) 0 0/60px 18px;
          }
        }
        .contact-button {
          font-size: 16px;
          color: ${theme.colors.type_dark_heading};
          margin: 0;
          min-width: 123px;
          border-radius: 9999px;
          border: 1px solid ${theme.colors.background_accent};
          transition: all .4s cubic-bezier(.7,0,.3,1);

          &:hover {
            background-color: ${theme.colors.brand_secondary_blue};
            color: ${theme.colors.white};
          }
        }
        .savings-button {
          margin: 0;
          margin-left: 0;
          padding: 0 24px;
          font-size: 16px;
          border: 1px solid ${theme.colors.brand_primary_red};
          color: ${theme.colors.type_dark_heading};
          background-color: ${theme.colors.white};
          transition: all .4s cubic-bezier(.7,0,.3,1);

          &:hover {
            background-color: ${theme.colors.brand_primary_red};
            color: ${theme.colors.white};
          }

          ${theme.mediaQueriesMaxWidth.xxl} {
            height: 45px;
            margin-left: 8px;
            padding: 0 16px;
            font-size: 14px;
          }
        }
        .animated-icon {
            width: 18px;
            height: 18px;
            position: relative;
            margin: 0px;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .5s ease-in-out;
            -moz-transition: .5s ease-in-out;
            -o-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
            cursor: pointer;
            }
          .animated-icon span {
            display: block;
            position: absolute;
            height: 2px;
            width: 18px;
            border-radius: 9999px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
            }
          .animated-icon span {
            background: ${theme.colors.line_stroke_dark};
          }
          .animated-icon span:nth-child(1) {
            top: 0;
          }
          .animated-icon span:nth-child(2), .animated-icon span:nth-child(3) {
            top: 8px;
          }
          .animated-icon span:nth-child(4) {
            top: 16px;
          }
          .animated-icon.open span:nth-child(1) {
            top: 11px;
            width: 0%;
            left: 50%;
          }
          .animated-icon.open span:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          .animated-icon.open span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
          .animated-icon.open span:nth-child(4) {
            top: 11px;
            width: 0%;
            left: 50%;
          }
          .navbar-toggler {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 4px;
            width: 48px;
            height: 48px;
            background: ${theme.colors.background_accent};
            border: none;
            border-radius: 100%;
          }
          .d-inline-flex {
              gap: 8px;

              &.left-side {
                gap: 32px;
              }
          } 
          .phone {
            display: none;
            width: 100%;
            height: auto;
            float: left;
          }
          
      }
      ${theme.mediaQueriesMaxWidth.xxl} {
        #header .pro-sidebar {
          width: 100%;
          transition: all .4s cubic-bezier(.7,0,.3,1);
        }
        #header .pro-sidebar.collapsed {
          padding-left: 100%;
          transition: all .4s cubic-bezier(.7,0,.3,1);
        }
        nav{
          background-color : ${theme.colors.white};
          min-height: 72px;
          border-bottom: 1px solid ${theme.colors.background_tint_dark};
          &.pro-menu {
            border-bottom: none;
          }
          .phone {
            margin-top:5px;
            display: block;
            width: 100%;
            height: auto;
            float: left;
          }
          .nav-link {
            display: none;
            color : ${theme.colors.white} !important;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: .4px;

           }
         }
    
       }
   
     
   
`;

