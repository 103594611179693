import styled from 'styled-components';
import {theme} from '../../../theme';

export const TextInputWrappper =  styled.div`
    position:relative;
    padding-bottom: ${props=>props.hide ? '0' : '1px'};
    margin-bottom: ${props=>props.hide ? '0' : '5px'};
    width:${props=>props.fullWidth ? '100%' : 'calc(50% - 12px)'};
    ${theme.mediaQueriesMaxWidth.sm}{
        width:${props=>props.fullWidth ? '100%' : '100%'};
    }
`;

export const InputWrappper =  styled.div`

    position:relative;
    margin-bottom:${props=>props.hide ? '0px' : '19px'};
    label {
        display: block;
        margin-bottom: 8px;
        text-align: left;
        font-size: 16px;
        color: ${theme.colors.type_supporting_text};
        line-height: 150%;
        font-family: ${theme.fonts.Inter_regular};

        ${theme.mediaQueriesMaxWidth.md}{
            font-size: 14px;
        }
    }
    input {
        width:100%;
        height:56px;
        border: 1px solid ${theme.colors.line_stroke_medium};
        box-sizing: border-box;
        border-radius: 8px;
        padding: 16px;
        color: ${theme.colors.type_body_text};
        font-size: 16px;
        line-height: 150%;
        font-family: ${theme.fonts.Inter_regular};

        &::placeholder {
            color: ${theme.colors.type_caption};
            font-size: 16px;
            line-height: 150%;
            font-family: ${theme.fonts.Inter_regular};
        }
    }
    span {
        margin-bottom:25px;
        position:absolute;
        z-index:1;
        margin-top:10px;
        padding-right:15px;
        svg{
            height:18px;
            width:18px;
        }
    }
    width: 100%;

    ${theme.mediaQueriesMaxWidth.md}{
        margin-bottom:${props=>props.hide ? '0px' : '11px'};

        input {
            height: 46px;
        }
    }
`;