import { AccordionItemStyled } from "./styled";
import React from "react";

const Accordion = ({ head, body, opened, handleOnClick }) => {
  const accordionBody = React.useRef(null);

  return (
    <AccordionItemStyled bodyHeight={accordionBody} open={opened}>
      <div className="acc-container">
        <div className="acc-head-container" onClick={handleOnClick}>
          <span className="acc-head">{head}</span>
          <span className="acc-plus">
            <span className="acc-plus-line"></span>
          </span>
        </div>

        <div ref={accordionBody} className="acc-body">{body}</div>
      </div>
    </AccordionItemStyled>
  );
};

export default Accordion;
