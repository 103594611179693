import styled from 'styled-components';
import { theme } from '../../../theme';

export const DropdownWrappper = styled.div`
    position: relative;
    padding-bottom: 19px;
    margin-bottom: 5px;
    width: ${(props) => (props.fullWidth ? '100%' : '350px')};
    ${theme.mediaQueriesMaxWidth.lg} {
        padding-bottom: 11px;
    }
    ${theme.mediaQueriesMaxWidth.md} {
        width: 100%;
    }
`;

export const DropdownWrap = styled.div`
    margin-top: 19px;
    p {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 150%;
        color: ${theme.colors.type_supporting_text};
        font-family: ${theme.fonts.Inter_regular};
    }
    ${theme.mediaQueriesMaxWidth.lg} {
        margin-top: 0;
        p {
            font-size: 14px;
        }
    }
    ${theme.mediaQueriesMaxWidth.md} {
        p {
            font-size: 14px;
            font-weight: normal;
        }
    }
`;
