import React from 'react';
import {
  NavbarWrapper
} from './NavbarMenuStyled';
import {
  PrimaryButton
} from '../PrimaryButton';
import {
  Navbar,
  Nav,
  Container,
} from 'react-bootstrap';
import { theme } from '../../theme';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { OutlineButton } from '../OutlineButton';
import { navigate } from '@reach/router';

export const NavbarMenu = ({menuCollapse,setMenuCollapse}) => {
        const menuIconClick = () => {
          menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
        };
        
    return (
      <div>
      <NavbarWrapper as="header">
        <Navbar className="navbar-secondary d-none d-xxl-flex justify-content-between" aria-label="secondary-menu" expand="*">
          <Container>
            <div className="d-inline-flex align-items-center left-menu">
              <Nav.Link href="https://www.easifleet.com.au/member/">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M7.9999 7.1999C9.32538 7.1999 10.3999 6.12539 10.3999 4.7999C10.3999 3.47442 9.32538 2.3999 7.9999 2.3999C6.67442 2.3999 5.5999 3.47442 5.5999 4.7999C5.5999 6.12539 6.67442 7.1999 7.9999 7.1999Z" fill="white"/>
                    <path d="M2.3999 14.3999C2.3999 11.3071 4.90711 8.7999 7.9999 8.7999C11.0927 8.7999 13.5999 11.3071 13.5999 14.3999H2.3999Z" fill="white"/>
                  </svg>
                </span>
                <span className="text">Sign In</span>
              </Nav.Link>
              <Nav.Link href="tel:1300266828">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M1.6001 2.4001C1.6001 1.95827 1.95827 1.6001 2.4001 1.6001H4.1224C4.51347 1.6001 4.84722 1.88283 4.91151 2.26858L5.50296 5.81728C5.5607 6.1637 5.38574 6.50728 5.07162 6.66434L3.83311 7.28359C4.72614 9.50275 6.49744 11.2741 8.7166 12.1671L9.33586 10.9286C9.49292 10.6145 9.83649 10.4395 10.1829 10.4972L13.7316 11.0887C14.1174 11.153 14.4001 11.4867 14.4001 11.8778V13.6001C14.4001 14.0419 14.0419 14.4001 13.6001 14.4001H12.0001C6.25634 14.4001 1.6001 9.74386 1.6001 4.0001V2.4001Z" fill="white"/>
                  </svg>
                </span>
                <span className="text">1300 266 828</span>
              </Nav.Link>
              <Nav.Link href="https://support.easifleet.com.au/">Support Hub</Nav.Link>
            </div>
            <div className="d-inline-flex align-items-center right-menu">
              <Nav.Link href="https://www.easifleet.com.au/about-us/">About us</Nav.Link>
              <Nav.Link href="https://www.easifleet.com.au/salary-packaging/">Salary packaging</Nav.Link>
              <Nav.Link href="https://www.easifleet.com.au/employers/">Employer</Nav.Link>
            </div>
          </Container>
        </Navbar>
        <Navbar className="navbar-primary" aria-label="primary-menu" expand="*">
          <Container>
            <div className="d-inline-flex align-items-center left-side">
              <Navbar.Brand href="https://www.easifleet.com.au/" className="logo"/>
              <div className="d-inline-flex align-items-center">
                <Nav.Link href="https://www.easifleet.com.au/why-easi/">Why Easi?</Nav.Link>
                <Nav.Link href="https://www.easifleet.com.au/how-it-works/">How it works</Nav.Link>
                <Nav.Link href="https://www.easifleet.com.au/how-much-will-i-save/">How much will I save?</Nav.Link>
                <Nav.Link href="https://www.easifleet.com.au/browse-cars/">Browse cars</Nav.Link>
                <Nav.Link href="https://www.easifleet.com.au/helpful-guides/">Learn</Nav.Link>
              </div>
            </div>
            <div className="d-inline-flex align-items-center">
              <PrimaryButton handleOnclick={() => {navigate('https://www.easifleet.com.au/contact/')}} btnText={'Talk to us'} bg={theme.colors.background_accent} margin={'0'} className="contact-button d-none d-xxl-block" />
              <OutlineButton handleOnclick={() => {navigate('https://calculator.easifleet.com.au/')}} btnText={'Calculate savings'} danger={true} round={true} className="savings-button" />
              <div className="closemenu d-flex d-xxl-none" onClick={menuIconClick}>
                {/* changing menu collapse icon on click */}
                {menuCollapse ? (
                    <button className="navbar-toggler nav-toggle-btn" type="button" aria-expanded="false"
                    aria-label="Toggle navigation">
                  
                    <div className="animated-icon"><span></span><span></span><span></span><span></span></div>
                  </button>
                    
                ) : ( 
                  <button className="navbar-toggler nav-toggle-btn" type="button" aria-expanded="false"
                  aria-label="Toggle navigation">
                
                  <div className="animated-icon"><span className="top-bar"></span><span className="middle-bar"></span><span className="middle-bar"></span><span className="bottom-bar"></span></div>
                </button>
                )}
              </div>
            </div>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="#home">1300 266 828</Nav.Link>
                <Nav.Link href="#link">Request a callback</Nav.Link>
                <Nav.Link href="#link">Login</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div id="header" >
          <ProSidebar collapsed={menuCollapse}>
            <SidebarContent>
              <Menu iconShape="square">
                <MenuItem ><a href="https://www.easifleet.com.au/why-easi/">Why Easi?</a></MenuItem>
                <MenuItem ><a href="https://www.easifleet.com.au/how-it-works/">How it works</a></MenuItem>
                <MenuItem ><a href="https://www.easifleet.com.au/how-much-will-i-save/">How much will I save?</a></MenuItem>
                <MenuItem ><a href="https://www.easifleet.com.au/browse-cars/">Browse cars</a></MenuItem>
                <MenuItem ><a href="https://www.easifleet.com.au/helpful-guides/">Learn</a></MenuItem>

                <div className="sidemenu">
                  <MenuItem ><a href="https://www.easifleet.com.au/about-us/">About Us</a></MenuItem>
                  <MenuItem ><a href="https://www.easifleet.com.au/salary-packaging/">Salary Packaging</a></MenuItem>
                </div>

                <div className="sidemenu secondary">
                  <MenuItem >
                    <a href="https://www.easifleet.com.au/member/">
                      <span className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path d="M7.9999 7.1999C9.32538 7.1999 10.3999 6.12539 10.3999 4.7999C10.3999 3.47442 9.32538 2.3999 7.9999 2.3999C6.67442 2.3999 5.5999 3.47442 5.5999 4.7999C5.5999 6.12539 6.67442 7.1999 7.9999 7.1999Z" fill="white"/>
                          <path d="M2.3999 14.3999C2.3999 11.3071 4.90711 8.7999 7.9999 8.7999C11.0927 8.7999 13.5999 11.3071 13.5999 14.3999H2.3999Z" fill="white"/>
                        </svg>
                      </span>
                      <span className="text">Sign In</span>
                    </a>
                  </MenuItem>
                  <MenuItem ><a href="https://support.easifleet.com.au/">Support Hub</a></MenuItem>
                </div>
              </Menu>

              <nav className='floating-content'>
                <PrimaryButton handleOnclick={() => {navigate('https://www.easifleet.com.au/contact/')}} btnText={'Talk to us'} bg={theme.colors.background_accent} margin={'0'} className="contact-button" />
              </nav>
            </SidebarContent>
          </ProSidebar>
        </div>
    </NavbarWrapper>
    </div>
    
    );
};
