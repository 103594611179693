import { Button } from 'react-bootstrap';
import React from 'react';
import { navigate } from '@reach/router';

export const BackButton = ({className, btnText, backIcon})=>{
    return (
        <Button 
            variant='secondary'
            className={className}
            onClick={()=>navigate(-1)}
        >
            {
                backIcon ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10 12.6666L5.33333 7.99992L10 3.33325" stroke="#080808" strokeLinecap="round" strokeLinejoin="round"/>
                </svg> : null
            }
            {btnText}
        </Button>
    )
}