import styled from 'styled-components';
import {theme} from '../../../theme';

export const TextAreaWrappper =  styled.div`
    position:relative;
    padding-bottom: 1px;
    margin-bottom: 5px;
    width:${props=>props.fullWidth ? '100%' : '350px'};
    ${theme.mediaQueriesMaxWidth.sm}{
        width:${props=>props.fullWidth ? '100%' : '100%'};
    }
`;

export const InputWrappper =  styled.div`

    position:relative;
    label {
        display: block;
        margin-bottom: 8px;
        text-align: left;
        font-size: 16px;
        color: ${theme.colors.type_supporting_text};
        line-height: 150%;
        font-family: ${theme.fonts.Inter_regular};

        ${theme.mediaQueriesMaxWidth.md}{
            font-size: 14px;
        }
    }
    textarea {
        width:100%;
        height:100%;
        border: 1px solid ${theme.colors.line_stroke_medium};
        box-sizing: border-box;
        border-radius: 8px;
        padding: 16px;
        color: ${theme.colors.type_body_text};
        font-size: 16px;
        line-height: 150%;
        font-family: ${theme.fonts.Inter_regular};

        &::placeholder {
            color: ${theme.colors.type_caption};
            font-size: 16px;
            line-height: 150%;
            font-family: ${theme.fonts.Inter_regular};
        }
    }
    span {
        margin-bottom:25px;
        position:absolute;
        z-index:1;
        margin-top:10px;
        padding-right:15px;
        svg{
            height:18px;
            width:18px;
        }
    }
    width: 100%;
`;