import styled from 'styled-components';
import {theme} from '../../../theme';
export const RangeSliderWrappper =  styled.div`

        margin-bottom:24px;
        p{
            font-size:16px;
            font-family:${theme.fonts.Inter_regular};
            color:${theme.colors.type_supporting_text};
            margin-bottom:8px;

        }
        h3{
            font-size:20px;
            line-height:150%;
            color:${theme.colors.brand_primary_blue};
            font-family:${theme.fonts.Inter_bold};

        }    
        ${theme.mediaQueriesMaxWidth.lg}{
            margin-bottom: 0;
            padding: 16px 5px;
            &.add-margin-mobile {
                padding: 32px 5px 16px;
            }
            .range-slider-container {
                padding: 16px 0;
            }
            h3{
                font-size:20px;
                line-height:150%;
                color:${theme.colors.brand_primary_blue};
                font-family:${theme.fonts.Inter_bold};
    
            }   
            p{
                font-size:14px;
                font-weight:normal;
                color:${theme.colors.type_supporting_text};
            }
        }
        ${theme.mediaQueriesMaxWidth.md}{
            margin-bottom: 0;
            padding: 16px 15px;
            &.add-margin-mobile {
                padding: 32px 15px 16px;
            }
            h3{
                font-size:20px;
                line-height:150%;
                color:${theme.colors.brand_primary_blue};
                font-family:${theme.fonts.Inter_bold};
    
            }   
            p{
                font-size:14px;
                font-weight:normal;
                color:${theme.colors.type_supporting_text};
            }
        }

   
`;

