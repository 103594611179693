import styled from 'styled-components';

export const PlayerStyles = {
    margin: 'auto',
    maxWidth: '1066px',
    minHeight: window.innerWidth > 768 ? '600px' : '200px',
    borderRadius: '16px',
    overflow: 'hidden',
    backgroundColor: '#000',
};

export const PlayIcon = (
    <svg
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 36C0 16.1178 16.1178 0 36 0C55.8823 0 72 16.1178 72 36C72 55.8823 55.8823 72 36 72C16.1178 72 0 55.8823 0 36Z"
            fill="#3F35FF"
        />
        <path
            d="M47.1731 34.5541C48.0962 35.087 48.0962 36.4194 47.1731 36.9523L30.1868 46.7593C29.2637 47.2923 28.1099 46.6261 28.1099 45.5602L28.1099 25.9462C28.1099 24.8803 29.2637 24.2141 30.1868 24.7471L47.1731 34.5541Z"
            fill="white"
        />
    </svg>
);

export const ThankYouPageWrapper = styled.div`
    padding-top: 256px;

    @media screen and (min-width: 1440px) {
        .container {
            max-width: 1440px;
        }
    }

    .small-cloud {
        position: absolute;
        top: 220px;
        left: 26px;
    }

    .big-cloud {
        position: absolute;
        top: 220px;
        right: 150px;
    }

    .half-cloud {
        position: absolute;
        top: 430px;
        right: 0;
    }

    .dollar-icon1 {
        position: absolute;
        top: 500px;
        right: 60px;
    }
    .dollar-icon2 {
        position: absolute;
        top: 590px;
        left: 70px;
    }

    ${(props) => props.theme.mediaQueriesMaxWidth.md} {
        padding-top: 259px;

        .small-cloud {
            height: 45px;
            left: -30px;
            top: 112px;
        }

        .half-cloud {
            height: 42px;
            top: 527px;
        }

        .big-cloud {
            display: none;
        }

        .dollar-icon1 {
            width: 32px;
            height: 32px;
            top: 491px;
            right: 31px;
        }

        .dollar-icon2 {
            position: absolute;
            top: 252px;
            left: 10px;
        }
    }
`;

export const ThankYouSection = styled.section`
    position: relative;
    text-align: center;
    padding: 120px 0px 120px 0px;
    h1 {
        margin-bottom: 16px;
        font-size: 62px;
        line-height: 100%;
        font-family: ${(props) => props.theme.fonts.ITC_bold};
        color: ${(props) => props.theme.colors.type_dark_heading};
    }
    p {
        max-width: 710px;
        margin-left: auto;
        margin-right: auto;
        font-family: ${(props) => props.theme.fonts.Inter_regular};
        color: ${(props) => props.theme.colors.type_body_text};
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 24px;
    }

    button {
        min-width: 182px;
        height: 64px;
        margin: 0;
        font-size: 16px;
        line-height: 100%;
        font-family: ${(props) => props.theme.fonts.Inter_semibold};
        background-color: ${(props) =>
            props.theme.colors.brand_primary_red};
        border-radius: 9999px;

        &:hover {
            background-color: ${(props) =>
                props.theme.colors.button_hover_red};
            border-color: ${(props) =>
                props.theme.colors.button_hover_red};
        }
    }

    ${(props) => props.theme.mediaQueriesMaxWidth.xl} {
        padding: 0 20px 140px 20px;

        h1 {
            font-size: 36px;
            margin-bottom: 12px;
        }

        p {
            font-size: 16px;
            margin-bottom: 16px;

            br {
                display: none;
            }
        }

        button {
            min-width: 140px;
            height: 48px;
            font-size: 14px;
        }
    }

    ${(props) => props.theme.mediaQueriesMaxWidth.lg} {
        h1 {
            line-height: 110%;
        }
    }
`;

export const VideoSection = styled.section`
    padding: 0 150px 80px;
    text-align: center;
    h3 {
        position: absolute;
        left: 50%;
        bottom: 56px;
        max-width: 1066px;
        width: 100%;
        margin-bottom: 0;
        padding-left: 56px;
        padding-right: 510px;
        transform: translateX(-50%);
        text-align: left;
        font-family: ${(props) => props.theme.fonts.ITC_bold};
        color: ${(props) => props.theme.colors.white};
        font-size: 36px;
        line-height: 110%;
    }

    .button-wrapper,
    .logo-wrapper {
        position: absolute;
        left: 50%;
        bottom: 56px;
        max-width: 1066px;
        width: 100%;
        padding-right: 56px;
        transform: translateX(-50%);
        text-align: right;
    }

    .logo-wrapper {
        top: 52px;
        bottom: unset;
        padding-left: 56px;
        text-align: left;

        img {
            width: 80px;
        }
    }

    button {
        min-width: 180px;
        height: 64px;
        margin: 0;
        background-color: ${(props) =>
            props.theme.colors.brand_primary_red};
        border: none;
        border-radius: 9999px;
        font-size: 16px;
        font-family: ${(props) => props.theme.fonts.Inter_semibold};
        line-height: 100%;

        &:hover,
        &:focus-visible {
            background-color: ${(props) =>
                props.theme.colors.button_hover_red};
        }
    }

    img {
        max-width: 1066px;
        width: 100%;
    }

    ${(props) => props.theme.mediaQueriesMaxWidth.xl} {
        h3 {
            padding-right: 290px;
        }
    }

    ${(props) => props.theme.mediaQueriesMaxWidth.lg} {
        padding: 85px 20px;

        h3 {
            position: relative;
            left: 0;
            bottom: 0;
            transform: none;
            padding: 16px 32px;
            width: auto;
            font-size: 20px;
            line-height: 110%;
            color: ${(props) => props.theme.colors.type_dark_heading};
            text-align: center;
        }

        .button-wrapper {
            position: relative;
            left: 0;
            bottom: 0;
            transform: none;
            padding-right: 0;
            text-align: center;

            button {
                height: 48px;
                min-width: 136px;
                font-size: 14px;
            }
        }

        .logo-wrapper {
            top: 20px;
            padding-left: 20px;
        }
    }

    ${(props) => props.theme.mediaQueriesMaxWidth.md} {
        padding: 25px 20px 85px;
    }
`;

export const PlayerWrapper = styled.div`
    position: relative;
    height: 600px;
    svg {
        path {
            transition: fill 0.5s ease;
        }
    }
    svg:hover {
        circle {
            stroke: red;
        }
        path:first-child {
            fill: red;
        }
    }

    ${(props) => props.theme.mediaQueriesMaxWidth.md} {
        height: 200px;

        svg {
            width: 42px;
            height: 42px;
        }
    }
`;

export const FAQSection = styled.section`
    display: flex;
    justify-content: space-between;
    padding: 80px;
    h1 {
        text-align: left;
        font-family: ${(props) => props.theme.fonts.ITC_bold};
        font-size: 48px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.type_dark_heading};
    }
    .description {
        margin-top: 24px;
        font-family: ${(props) => props.theme.fonts.Inter_regular};
        font-size: 20px;
        line-height: 140%;
        color: ${(props) => props.theme.colors.type_body_text};
        text-align: left;
    }
    .faq-support {
        margin-top: 24px;
        margin-bottom: 40px;
        font-family: ${(props) => props.theme.fonts.Inter_regular};
        font-size: 20px;
        line-height: 140%;
        font-weight: 400;
        color: ${(props) => props.theme.colors.type_body_text};
    }
    .faq-support-button {
        min-width: auto;
        height: 64px;
        margin: 0;
        padding: 0 32px;
        border: 0;
        border-radius: 9999px;
        font-size: 16px;

        &:hover {
            background-color: ${(props) =>
                props.theme.colors.button_hover_red};
        }
    }
    .faq-button {
        min-width: auto;
        margin: 16px 0 0;
        padding: 16px 24px;
        border: 0;
        border-radius: 9999px;
        color: ${(props) => props.theme.colors.type_dark_heading};
        font-size: 16px;

        &:hover {
            background-color: ${(props) =>
                props.theme.colors.brand_secondary_blue};
            color: ${(props) => props.theme.colors.white};
        }
    }
    ${(props) => props.theme.mediaQueriesMaxWidth.xl} {
        padding: 48px 0;

        h1 {
            font-size: 30px;
            margin-bottom: 30px;
        }
        .faq-container {
            padding: 0px;
        }
    }

    ${(props) => props.theme.mediaQueriesMaxWidth.lg} {
        .text-start {
            margin-bottom: 32px;
        }

        h1 {
            font-size: 32px;
            margin-bottom: 16px;
        }
        .faq-support {
            margin-top: 16px;
            margin-bottom: 24px;
            font-size: 18px;
        }

        .faq-support-button {
            height: 48px;
            padding: 0 20px;
            font-size: 14px;
        }
    }

    ${(props) => props.theme.mediaQueriesMaxWidth.md} {
        padding: 48px 20px;
    }
`;
