import styled from 'styled-components';
import { theme } from '../../theme';
export const LeaseCalculatorWrappper = styled.div`

     .Lease-section{
        position: relative;
        padding: 236px 132px 0px 132px;
        .switch-buttons {
         gap: 24px;

         button {
            margin: 0;
         }
        }
        .counter{
           position:sticky;
           top: 180px;
           margin-left: 100px;
           ${theme.mediaQueriesMaxWidth.xl}{
               margin-left:10px;
            }
           ${theme.mediaQueriesMaxWidth.lg}{
               margin-top: 96px;
               margin-left: 0;
            }
        }
        .quote-error{
            background-color: ${theme.colors.red};
            color: ${theme.colors.white};
            border-radius: 15px;
            padding: 32px 16px;
            width: 345px;
            margin: 20px 0;

            ${theme.mediaQueriesMaxWidth.md} {
                width: 270px;
            }
            ${theme.mediaQueriesMaxWidth.sm} {
                width: auto;
            }

            a{
                color: ${theme.colors.white};

                &:hover, &:focus-within{
                    text-decoration: underline;
                }
            }
        }
        .sticky {
            position: absolute;
            bottom: -35px;
        }
        .big-cloud{
           position:fixed;
           top:120px;
           left:60%;
        }
        .dollar-icon1{
           position:fixed;
           top:480px;
           right:40px;
        }
        .lightning-icon1{
         position:fixed;
         top:433px;
         right:40px;
        }
        .half-cloud{
           position:fixed;
           top:410px;
           right:0;
        }
        .dollar-icon2{
           position:fixed;
           top:670px;
           right:180px;
        }
        .lightning-icon2{
           position:fixed;
           top:670px;
           right:180px;
        }
        .small-cloud{
           position:fixed;
           top:740px;
           left: 60%;
        }

     }
     .pb{
      padding-bottom: 178px
     }
     .car-icon{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
     }
     .Include {
         font-family: ${theme.fonts.Inter_semibold};
         margin-top:32px;
         margin-left:0px;
         color:${theme.colors.brand_primary_blue};
     }
     .lease-heading{
        max-width: 730px;
        color:${theme.colors.type_dark_heading};
        font-size: 62px;
        font-family:${theme.fonts.ITC_bold};

     }
     .Lease-paragraph{
         margin-bottom: 24px;
         color:${theme.colors.type_caption};
         font-size: 20px;
         line-height: 130%;
         font-family:${theme.fonts.Inter_medium};

         ${theme.mediaQueriesMaxWidth.lg} {
            margin-bottom: 12px;
            font-size: 14px;
            line-height: 140%;
            font-family:${theme.fonts.Inter_regular};
         }
     }
     .looking-for{
         margin-top:80px;
         margin-bottom: 24px;
         color: ${theme.colors.type_dark_heading};
         font-size: 32px;
         line-height: 120%;
         font-family:${theme.fonts.Inter_bold};
     }
     .cant-find {
      margin-top: 40px;
      margin-bottom: 40px;
      font-size: 16px;
      color: ${theme.colors.type_dark_heading};
      font-family:${theme.fonts.Inter_semibold};
      line-height: 140%;
      a {
         color: ${theme.colors.type_dark_heading};
         text-decoration: underline;
         text-underline-offset: 3px;
      }

      ${theme.mediaQueries.lg} {
         line-height: 100%;

         br {
            display: none;
         }
      }
     }
     .p-color{
         font-size: 14px !important;
         max-width:500px;
      }
      .ev-category-text{
         display:none;
      }
     .Vehicle-size{
        margin-top: 40px;
        padding: 40px 0 0;
        img{
            display: block;
            margin-left: auto;
            margin-right: auto;
            height:auto;
            width:auto;
            max-width:100%;
            vertical-align: middle;
        }
        p{
            margin-top: 24px;
            margin-bottom: 40px;
            color:${theme.colors.type_supporting_text};
            font-size: 24px;
            font-family:${theme.fonts.Inter_semibold};

        }
        span{
           color: ${theme.colors.red};
           margin-left:5px;
        }
        .drop-list{
            font-size:16px;
            font-family:${theme.fonts.Replica_bold};
            cursor: pointer;


        }
         .spend-range {
            margin-bottom: 0;

            h3 {
               margin-bottom: 0;
            }

            p {
               margin-bottom: 8px;
               font-size: 16px;
               font-family: ${theme.fonts.Inter_regular};
            }
         }
     }
     .set-items{
      padding-bottom:30px;
     }
     h2 {
      color:${theme.colors.easiblue};
      font-size: 26px;
      font-family:${theme.fonts.Replica_Regular};
      text-align: left;
      margin-left:15px;
     }
     .switch-buttons {
      display: flex;
     }
     .Lease-button{
         margin-top: 26px;
         max-width: 345px;
         font-family: ${theme.fonts.Inter_medium};

         button {
            margin: 0;
            border: 1px solid ${theme.colors.brand_primary_red};
            border-radius: 9999px;
            background-color: ${theme.colors.brand_primary_red};
            color: ${theme.colors.white};
            transition: all .4s cubic-bezier(.7,0,.3,1);

            &:hover {
               background-color: ${theme.colors.button_hover_red};
               border-color: ${theme.colors.button_hover_red};
               color: ${theme.colors.white};
            }
         }

         .email-button {
            button {
               margin-top: 12px;
               background-color: ${theme.colors.background_accent};
               border: 1px solid ${theme.colors.background_accent};
               color: ${theme.colors.type_dark_heading};
               transition: all .4s cubic-bezier(.7,0,.3,1);

               &:hover {
                  background-color: ${theme.colors.brand_secondary_blue};
                  border-color: ${theme.colors.brand_secondary_blue};
                  color: ${theme.colors.white};
               }
            }
         }

         ${theme.mediaQueriesMaxWidth.sm}{
            width:100%;
            margin-top:32px;
            max-width:initial;
            button{
               width:100%;
         }
        }
        p{
         max-width: 298px;
         margin-right: auto;
         margin-left: auto;
         margin-top: 30px;
         font-size: 14px;

         font-family:${theme.fonts.Replica_Regular};
         color:${theme.colors.easiblue};
        }
     }
     ${theme.mediaQueriesMaxWidth.ipadpro} {
      .Lease-section{
         padding: 120px 20px 0px 20px;
      }
      .lease-heading{
         font-size: 50px;
         }
      .Lease-images{
          .icon3-img{
            right: -100px;
        }
         .icon5-img  {
            right: 80px;
            top: 525px;
         }
         .icon2-img {
            left: -50px;
         }
          }

      }
     }
     ${theme.mediaQueriesMaxWidth.lg}{
      .Lease-section{
         padding: 120px 20px 0px 20px;
      }
      .pb{
         padding-bottom: 146px
      }
      .lease-heading{
         margin-bottom: 0;
         font-size: 36px;
         line-height: 110%;
      }
      .switch-buttons {
         gap: 16px;
         margin: 0 -15px;
         overflow-x: auto;
         padding: 0 15px 5px;

         &::-webkit-scrollbar {
            display: none;
         }

         button {
            border-width: 2px;
            padding: 0 8px;

            &.long-button {
               min-width: 148px;
            }

            &.longer-button {
               min-width: 160px;
            }

            &.much-longer-button {
               min-width: 220px;
            }
         }
      }
      .cant-find {
         margin-top: 19px;
         margin-bottom: 24px;
      }
      

      .Vehicle-size {
         padding: 24px 0 11px;

         p {
            margin-top: 0;
            margin-bottom: 0;
         }
         .spend-range {
            margin-top: 11px;

            p {
               margin-bottom: 0;
               color: ${theme.colors.type_supporting_text};
               font-size: 14px;
               line-height: 140%;
               font-family: ${theme.fonts.Inter_regular};
            }
         }
         .ev-category-text{
         margin-bottom:46px;
            display:flex;
            flex-direction:column;
            gap:32px;
            button{
               font-family: Inter;
               font-size: 20px;
               font-style: normal;
               font-weight: 600;
               line-height: 120%; /* 24px */
               background:none;
               border:none;
               text-align:left
            }
         }
      }
         
      .Lease-images{
         img{ display:none;}
      }
      .Include {
         font-family: ${theme.fonts.Inter_semibold};
         margin-top:48px;
         margin-left:0px;
         color:${theme.colors.brand_primary_blue};
      }
     }

     }
     ${theme.mediaQueries.md}{
      .set-items{
         display:none;
        }
     }
     ${theme.mediaQueriesMaxWidth.md}{
      .looking-for{
         margin-top:48px;
         margin-bottom: 16px;
         font-size: 26px;
         font-family: ${theme.fonts.Inter_semibold};
      }
      .Include {
         text-align:center;
      }
      .lease-heading{
         font-size: 36px;
      }
      .Lease-section{
         padding: 136px 15px 60px 15px;
      }
      .pb{
         padding-bottom: 0px
      }
      .Vehicle-size {
         img{
            display: block;
            margin-left: auto;
            margin-right: auto;
            height:auto;
            width:100%;
            vertical-align: middle;
         }
         p{
               color:${theme.colors.brand_primary_blue};
               font-size: 20px;
               line-height: 120%;
               font-weight:normal;
            }
            .spend-range {
               margin-top: 11px;

               p {
                  margin-bottom: 0;
                  font-size: 14px;
                  line-height: 140%;
                  font-family: ${theme.fonts.Inter_regular};
               }
            }
         }
         .Lease-images{
            position:relative;
            img{ display:none;}
         }
     }
     ${theme.mediaQueries.sm}{
      .set-items{
         display:none;
        }

     }

     ${theme.mediaQueriesMaxWidth.sm}{
      .mbl-div{
         padding-bottom:32px;
      }
     }
     .only-mobile{
        display:inherit;
         ${theme.mediaQueries.onlyDesktop}{
            display:none;
         }
     }

     .text-easiblue{
      color:${theme.colors.easiblue};
     }


`;

export const LeasCardsWrapper = styled.div`
    max-width: 750px;
    margin-left: auto;
`;
export const LeasPackageWrapper = styled.div`
    max-width: 345px;
    border-radius: 10px;
    margin-top: 24px;
    margin-bottom: 32px;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 4px 30px rgba(40, 39, 129, 0.15);
    padding: 32px 16px;
    .Include {
        margin-top: 0px;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 24px;
    }
    ${theme.mediaQueriesMaxWidth.md} {
        width: 270px;
    }
    ${theme.mediaQueriesMaxWidth.sm} {
        display: none;
    }
`;
export const BreakDownTable = styled.div`
    max-width: 750px;
    margin-left: auto;
    ${theme.mediaQueriesMaxWidth.sm} {
        display: none;
    }
`;
export const CardFlex = styled.div`
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    ${theme.mediaQueriesMaxWidth.sm} {
        display: none;
    }
`;

export const CalculatorDiv = styled.div`
    max-width: 750px;
    margin-left: auto;
    text-align: left;
`;

export const Tabs = styled.div`
    max-width: 526px;
    display: flex;
    justify-content: space-between;

    ${theme.mediaQueriesMaxWidth.lg} {
        gap: 20px;
        margin-bottom: 32px;
    }
`;
export const Tab = styled.p`
    color: #6b7788 !important;
    line-height: 140%;
    cursor: pointer !important;
    ${(props) =>
        props.isActive &&
        `
      color:${theme.colors.brand_primary_blue} !important;
      text-decoration: underline;
      text-underline-offset: 4px;
   `}
`;

export const TabSection = styled.div``;
