import React from 'react';
import {theme} from '../../../theme';
import styled from 'styled-components/macro';

export const LabelStyled = styled.label`
    color: ${props => props.theme.colors.type_supporting_text};
    font-family:${theme.fonts.Inter_regular};
    ${({ insideField }) => insideField && `
        margin-bottom: 8px;
        position:relative;
        font-size:16px;
        line-height:150%;

        ${theme.mediaQueriesMaxWidth.md} {
            font-size: 14px;
            line-height: 140%;
        }
    `}

    .quote-section & {
        display: block;
        text-align: left;
    }
`;

export const Label = (props) => {
    return (
        <LabelStyled as={props.as} htmlFor={props.for} insideField={props.insideField}>
            {props.text}
        </LabelStyled>
    );
};
