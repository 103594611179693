import {React} from 'react';
import {
    DropdownWrappper
} from './DropdownStyled';
import Select from 'react-select';
import { Label } from '../Label';
import { theme } from '../../../theme'; 
import {useField, useFormikContext } from 'formik';
import { Error } from '../Error';

export const Dropdown = ({label,options,name,innerLable, placeholder,customHandle,className, fullWidth,value, noOptionsMessage, handleMenuClose,handleMenuOpen}) => {
  const { setFieldValue} = useFormikContext();
  const meta = useField(name)[1];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: '56px',
      border: `1px solid ${theme.colors.line_stroke}`,
      borderRadius: '8px',
      padding: '0 16px 0 8px',

      '@media screen and (max-width: 768px)': {
        height: '40px',
        padding: '0 12px 0 4px',

        '.quote-section &': {
          height: '46px',
        }
      }
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "rgba(8, 8, 8, 1)" // Custom colour
    }),
    indicatorsContainer: base => ({
      ...base,

      'svg': {
        fill: 'none',
        strokeWidth: 'initial',

        'path': {
          d: 'path("M12.6666 6L7.99998 10.6667L3.33331 6")',
          stroke: "rgba(8, 8, 8, 1)",
        }
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.colors.type_caption,
      fontFamily: theme.fonts.Inter_regular,

      '@media screen and (max-width: 768px)': {
        margin: '0',
      }
    }),
    singleValue: (provided) => ({
        ...provided,
        color: theme.colors.type_body_text,
        fontFamily: theme.fonts.Inter_semibold,
    }),
    menu: (provided) => ({
      ...provided,
      color: theme.colors.type_body_text,
      fontFamily: theme.fonts.Inter_semibold,
      borderRadius: '8px',
      border: `1px solid ${theme.colors.line_stroke_medium}`,
      background: 'white',
      zIndex: '100',
      textAlign: 'left',
      lineHeight: '150%',
      maxHeight: '320px',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '320px',

      "&::-webkit-scrollbar": {
        width: '16px',
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: '9999px',
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.colors.background_medium,
        borderRadius: '9999px',
        borderTop: `2px solid ${theme.colors.background_white}`,
        borderBottom: `2px solid ${theme.colors.background_white}`,
        borderLeft: `4px solid ${theme.colors.background_white}`,
        borderRight: `4px solid ${theme.colors.background_white}`,
        backgroundClip: 'padding-box',
      },
    }),
    option: (provided, { data, isSelected }) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      width: 'auto',
      height: '56px',
      padding: '0 16px',
      margin: '0 4px',
      borderRadius: '12px',
      fontSize: '16px',
      background: isSelected ? theme.colors.background_accent : 'transparent',
      color: isSelected ? theme.colors.type_dark_heading : theme.colors.type_body_text,
      transition: 'all 0.2s ease',

      "&:hover": {
        background: theme.colors.background_accent,
        color: theme.colors.type_dark_heading,
      },
    }),
  };
  const handleOnChange = (option) => {
    setFieldValue(name, option ? option.value : '');
    if(customHandle) 
        {customHandle(option.value)
          
    };
  
  };

  if (value) {
    value = options
        ? options.find((item) => {
              return item.value === value;
          })
        : {};
    }  
    return (
      <DropdownWrappper
        fullWidth={fullWidth}
        className={className}
      >
        { label ?
        <Label
          for={name}
          text={label}
          insideField={innerLable}
        /> : null
        }
        <Select 
          name={name}
          value={value}
          placeholder={placeholder || "Select Option"}
          styles={customStyles}
          components={{
            IndicatorSeparator:() => null,
            DropdownIndicator:() => {
              return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M12.6666 6L7.99998 10.6667L3.33331 6" stroke="#080808" strokeLinecap="round" strokeLinejoin="round"/></svg>;
            }
          }}
          options={options}
          onChange={handleOnChange}
          noOptionsMessage={() => {
            return noOptionsMessage ? noOptionsMessage : 'No options available';
          }}
          onMenuClose={handleMenuClose}
          onMenuOpen={handleMenuOpen}
          />
          <Error meta={meta} />
      </DropdownWrappper>
    
    );
};
