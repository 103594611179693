import React from 'react';
import {TextInputWrappper, InputWrappper} from './TextInputStyled';
import { useField } from 'formik';
import {FiSearch} from 'react-icons/fi';
import { Error } from '../Error';

export const TextInput = ({placeholder, icon, fullWidth, handleChange, customHandle, name, hidden, value, label, ...props}) => {
    const meta = useField(name)[1];

    return (
        <TextInputWrappper hide={hidden} fullWidth={fullWidth}>
            <InputWrappper hide={hidden}
            >
                { icon && 
                <span><FiSearch/></span>
                }
                {
                    hidden && value ? null : 
                    (label ? <label htmlFor={name}>{label}</label> : null)
                }
                {
                    hidden && value ?
                    <input type="hidden" value={value} name={name} /> :
                    <input 
                        type="text"
                        name={name}
                        placeholder={placeholder}
                        onChange={customHandle? (e)=>customHandle(e.target.value) : handleChange}
                    />
                }
            </InputWrappper>
            <Error meta={meta} />
        </TextInputWrappper>
    );
};
