import styled from 'styled-components';
import { theme } from '../../theme';
export const MeterWrappper = styled.div`
    background: ${theme.colors.brand_primary_blue};
    width: 345px;
    box-shadow: 0px 4px 30px rgba(40, 39, 129, 0.15);
    border-radius: 15px;
    padding: 40px 24px;
    .heading {
        font-size: 24px !important;
        line-height: 140%;
        font-family: ${theme.fonts.Inter_semibold} !important;
    }
    .text {
        color: ${theme.colors.white};
        font-size: 16px;
        font-family: ${theme.fonts.Inter_medium};
        margin: 0px;
        letter-spacing: 0.1px;
        line-height: 130%;
    }
    h4 {
        color: ${theme.colors.brand_primary_blue};
        font-family: ${theme.fonts.ITC_bold};
        background: ${theme.colors.white};
        padding: 16px 5px 2px;
        font-size: 46px;
        margin: 0px 2.539px;
        min-width: 44px;
        border-radius: 8px;
        line-height: 120%;
    }
    span {
        color: ${theme.colors.brand_primary_blue};
        font-family: ${theme.fonts.ITC_bold};
        background: ${theme.colors.white};
        padding: 16px 5px 2px;
        font-size: 46px;
        margin: 0px 2.539px;
        min-width: 44px;
        border-radius: 8px;
        line-height: 120%;
    }
    .amount {
        display: flex;
        align-items: center;
        margin: 24px 0px;
        justify-content: center;
    }
    hr {
        margin-top: 40px;
        margin-bottom: 40px;
        border-color: ${theme.colors.brand_secondary_blue};
    }
    ${theme.mediaQueriesMaxWidth.lg} {
        width: 270px;
        .heading {
            font-size: 20px !important;
        }
        .amount {
            margin: 16px 0px;
        }
        h4 {
            font-size: 38px;
            min-width: 36px;
        }
        span {
            font-size: 38px;
            min-width: 36px;
        }
    }
    ${theme.mediaQueriesMaxWidth.md} {
        width: auto;
        h4 {
            min-width: 44px;
        }
        span {
            min-width: 44px;
        }
    }

    @property --num {
        syntax: '<integer>';
        initial-value: 0;
        inherits: false;
    }

    .numAnimate {
        animation: counter 0.5s infinite alternate ease-in-out;
        counter-reset: num var(--num);
        padding: 0;
    }
    .numAnimate::after {
        content: counter(num);
    }
    .revNumAnimate {
        animation: revCounter 0.5s infinite alternate ease-in-out;
        counter-reset: num var(--num);
        padding: 0;
    }

    .revNumAnimate::after {
        content: counter(num);
    }
    .lastNumAnimate {
        animation: lastCounter 0.5s infinite alternate ease-in-out;
        counter-reset: num var(--num);
        padding: 0;
    }

    .lastNumAnimate::after {
        content: counter(num);
    }
    @keyframes lastCounter {
        from {
            --num: 4;
        }
        to {
            --num: 8;
        }
    }

    @keyframes revCounter {
        from {
            --num: 9;
        }
        to {
            --num: 0;
        }
    }
    @keyframes counter {
        from {
            --num: 0;
        }
        to {
            --num: 9;
        }
    }
`;

export const SubCosts = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${theme.colors.white};
    margin-top: 24px;
`;

export const Cost = styled.div`
    text-align: left;
    font-size: ${theme.fontSizes.xs};
    font-family: ${theme.fonts.Replica_bold};
    p {
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 140%;
        font-family: ${theme.fonts.Inter_regular};
    }
    h3 {
        margin-bottom: 0;
        font-size: 32px;
        font-family: ${theme.fonts.Inter_semibold};
        span {
            padding: 0px;
            margin: 0px;
            background: transparent;
            font-size: 32px;
            font-family: ${theme.fonts.Inter_semibold};
            color: ${theme.colors.white};
        }
    }
`;
