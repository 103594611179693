import styled from 'styled-components';
export const DownArrow = (
    <svg
        width="14"
        height="9"
        viewBox="0 0 14 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7 7.49575L7.53028 8.02612C7.2374 8.31895 6.7626 8.31895 6.46972 8.02612L7 7.49575ZM0.344717 1.9022C0.0517976 1.60934 0.0517557 1.13446 0.344623 0.841543C0.637491 0.548624 1.11236 0.548582 1.40528 0.84145L0.344717 1.9022ZM12.5947 0.84145C12.8876 0.548582 13.3625 0.548624 13.6554 0.841543C13.9482 1.13446 13.9482 1.60934 13.6553 1.9022L12.5947 0.84145ZM6.46972 8.02612L0.344717 1.9022L1.40528 0.84145L7.53028 6.96537L6.46972 8.02612ZM13.6553 1.9022L7.53028 8.02612L6.46972 6.96537L12.5947 0.84145L13.6553 1.9022Z"
            fill="#DC1C23"
        ></path>
    </svg>
);

export const AccordionItemStyled = styled.div`
  .acc-container {
    overflow: hidden;
    margin-bottom: 24px;
    border-radius: 16px;
    font-family: "Replica", sans-serif;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    cursor: pointer;
    background-color: ${(props) =>
        props.theme.colors.background_tint_light};

    ${(props) => props.theme.mediaQueriesMaxWidth.lg} {
      margin-bottom: 12px;
    }
  }
  .acc-head {
    color: ${(props) => props.theme.colors.type_dark_heading};
    transition: 200ms ease-out;
    font-size: 18px;
    line-height: 150%;
    font-family: ${(props) => props.theme.fonts.Inter_semibold};
    padding-right: 15px;
    font-weight:600;
  }

  ${(props) => props.theme.mediaQueriesMaxWidth.sm}{
  .acc-head{
        font-size: 16px;
        
      }
  }
  .acc-body {
    ${(props) =>
        props.open
            ? 'height:' +
              props.bodyHeight.current.querySelector('p, div')
                  .clientHeight +
              'px;opacity:1;margin-bottom: 30px;'
            : 'height:0px;opacity:0;margin-bottom: 0px;'};
    overflow: hidden;
    padding-left: 24px;
    padding-right: 24px;
    color: ${(props) => props.theme.colors.type_body_text};
    font-size: 16px;
    line-height: 150%;
    font-family: ${(props) => props.theme.fonts.Inter_regular};
    transition: all 0.5s ease-out;

    p:last-child {
      margin-bottom: 0;
    }

    ${(props) => props.theme.mediaQueriesMaxWidth.lg} {
      ${(props) =>
          props.open ? 'margin-bottom: 24px;' : 'margin-bottom: 0;'};
      padding-left: 16px;
      padding-right: 16px;
      
    }
  }
  .acc-arrow {
    color: ${(props) => props.theme.colors.red};
    font-size: 20px;
    svg {
      transform: ${(props) =>
          props.open ? 'rotate(0deg)' : 'rotate(-90deg)'};
      transition: all 0.5s ease-in-out;
    }
  }
  .acc-head-container {
    background-color: ${(props) =>
        props.theme.colors.background_tint_light};
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.5s ease-in-out;

    ${(props) => props.theme.mediaQueriesMaxWidth.lg} {
      padding: 16px;
    }
  }
  .acc-head-container:hover {
    .acc-head {
      color: ${(props) => props.theme.colors.red};
      transition: all 0.5s ease-in-out;
    }
  }
  .acc-body-link {
    text-decoration: none;
    color: ${(props) => props.theme.colors.red};
  }
  .acc-body-link:hover {
    color: ${(props) => props.theme.colors.easiblue};
    transition: all 0.5s ease-in-out;
  }
  .acc-plus {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    background: ${(props) =>
        props.open
            ? props.theme.colors.background_white
            : props.theme.colors.brand_secondary_blue};
    border-radius: 100%;
    transition: all 0.5s ease-in-out;

    .acc-plus-line {
      position: relative;
      width: 20px;
      height: 2px;
      border-radius: 999px;
      background: ${(props) => props.theme.colors.background_white};
      transition: all 0.5s ease-in-out;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: ${(props) =>
            props.open
                ? props.theme.colors.brand_secondary_blue
                : props.theme.colors.background_white};
        border-radius: 999px;
        transform: ${(props) =>
            props.open ? 'rotate(0deg)' : 'rotate(90deg)'};
        transition: all 0.5s ease-in-out;
      }
    }

    ${(props) => props.theme.mediaQueriesMaxWidth.lg} {
      width: 32px;
      height: 32px;
      flex: 0 0 32px;

      .acc-plus-line {
        width: 12px;
        height: 2px;

        &:before {
          width: 100%;
          height: 100%;
        }
      }
  }
`;
