import React, { useEffect, useState } from 'react';
import { QuoteWrappper } from './QuoteStyled';
import { Col, Row } from 'react-bootstrap';
import { PrimaryButton } from '../PrimaryButton';
import { Dropdown } from '../FormFields/Dropdown';
import { OutlineButton } from '../OutlineButton';
import { TextInput } from '../FormFields/TextInput';
import { InternationalPhoneInput } from '../FormFields/InternationalPhoneInput';
import { Formik, Form } from 'formik';
import { sendQuoteEmail } from '../../actions/VehicleActions';
import { navigate, useLocation } from '@reach/router';
import {
    delay,
    stateOptions,
    timelineOptions,
    evOptions,
} from '../../utils/helpers';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { BackButton } from '../BackButton';
import { TextArea } from '../FormFields/TextArea';

export const QuoteContainer = ({
    makesOptions,
    modelOptions,
    bodyTypeOptions,
    variantOptions,
}) => {
    const loc = useLocation();
    const data = loc.state ? loc.state.leaseData : {};
    const cost_per_fortnight = loc.state
        ? loc.state.cost_per_fortnight
        : {};
    const saving_per_year = loc.state ? loc.state.saving_per_year : {};
    const fortnight_running_cost = loc.state
        ? loc.state.fortnight_running_cost
        : {};
    const fortnight_car_cost = loc.state
        ? loc.state.fortnight_car_cost
        : {};
    const list_price_gross = loc.state ? loc.state.list_price_gross : {};
    const list_price_net = loc.state ? loc.state.list_price_net : {};
    const quote_type = loc.state ? loc.state.quote_type : '';
    const car_type = loc.state ? loc.state.car_type : '';
    const isEv = loc.state ? loc.state.isEv : false;
    const tracking = loc.state ? loc.state.tracking : {};

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [leasebefore, setleasebefore] = useState(false);
    const initialValues = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '+61',
        employer: '',
        employerwebsite: 'www.',
        state: '',
        timeline: '',
        note: '',
        retail_car_price: list_price_gross,
        net_car_price: list_price_net,
    };
    const validationSchema = Yup.object({
        firstname: Yup.string().required('This field is required'),
        lastname: Yup.string().required('This field is required'),
        email: Yup.string().email().required('This field is required'),
        phone: Yup.number()
            .test(
                'len',
                'This field is required',
                (v) => v.toString().length > 3
            )
            .required('This field is required'),
        employer: Yup.string().required('This field is required'),
    });

    const handleSubmit = (values) => {
        if (JSON.stringify(data) !== JSON.stringify({})) {
            var make = getMake(data, makesOptions);
            var variant = getVariant(
                data,
                isEv ? evOptions : variantOptions
            );
            var vehicleModel = getModel(data, modelOptions);
            var bodyType = getBody(data, bodyTypeOptions);
        }
        var referrer = '';
        var car_photo = '';

        var params = { '': '' };
        var state = data.state ?? values.state;
        sendQuoteEmail(
            values,
            make,
            variant,
            vehicleModel,
            bodyType,
            data.salary,
            state,
            data.travelDistance,
            data.year,
            data.leaseTerm,
            referrer,
            car_photo,
            cost_per_fortnight,
            saving_per_year,
            fortnight_running_cost,
            fortnight_car_cost,
            params,
            quote_type,
            car_type,
            isEv,
            tracking
        );
        return delay(2000).then(() => {
            toast.success('Email has been sent.');
            navigate('/thankyou');
        });
    };

    const getMake = (data, makesOptions) => {
        var makelabel = makesOptions.find(
            (make) => make.value === data.vehicle_make
        );
        return makelabel?.label;
    };
    const getVariant = (data, variantOptions) => {
        var variantlabel = variantOptions.find(
            (variant) => variant.value === data.variants
        );
        return variantlabel?.label;
    };
    const getModel = (data, modelOptions) => {
        var modellabel = modelOptions.find(
            (model) => model.value === data.vehicle_model
        );
        return modellabel?.label;
    };
    const getBody = (data, bodyTypeOptions) => {
        var bodylabel = bodyTypeOptions.find(
            (bodyType) => bodyType.value === data.body_type
        );
        return bodylabel?.label;
    };
    console.log(leasebefore);

    return (
        <QuoteWrappper>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({ isSubmitting, handleChange, setFieldValue }) => {
                    return (
                        <Form>
                            <section className="quote-section">
                                <BackButton
                                    className="backbutton"
                                    btnText="Back"
                                    backIcon
                                />
                                <Row>
                                    <Col
                                        sm={12}
                                        md={8}
                                        xl={6}
                                        className="offset-md-2 offset-xl-3"
                                    >
                                        <p className="quote-paragraph">
                                            Finance your new car with Easi
                                        </p>
                                        <h1 className="quote-heading">
                                            Get a full quote
                                        </h1>
                                        <p>
                                            Please answer a few more
                                            questions and one of our
                                            novated leasing specialists
                                            will contact you to assist with
                                            a detailed quote.
                                        </p>
                                        <div className="form-style d-flex flex-wrap justify-content-between">
                                            <TextInput
                                                fullWidth
                                                placeholder="Total Car Price"
                                                name="retail_car_price"
                                                hidden={true}
                                                value={list_price_gross}
                                                handleChange={handleChange}
                                            />
                                            <TextInput
                                                fullWidth
                                                placeholder="Total Car Price"
                                                name="net_car_price"
                                                hidden={true}
                                                value={list_price_net}
                                                handleChange={handleChange}
                                            />
                                            <TextInput
                                                placeholder="Your first name"
                                                name="firstname"
                                                label="First name *"
                                                handleChange={handleChange}
                                            />
                                            <TextInput
                                                name="lastname"
                                                placeholder="Your last name"
                                                label="Last name *"
                                                handleChange={handleChange}
                                            />
                                            <TextInput
                                                fullWidth
                                                placeholder="Your email"
                                                name="email"
                                                label="Email *"
                                                handleChange={handleChange}
                                            />
                                            <InternationalPhoneInput
                                                fullWidth
                                                placeholder="Phone *"
                                                name="phone"
                                                label="Phone *"
                                                handleChange={(value) =>
                                                    setFieldValue(
                                                        'phone',
                                                        value
                                                    )
                                                }
                                            />
                                            <TextInput
                                                fullWidth
                                                placeholder="Your employer name"
                                                name="employer"
                                                label="Employer Name *"
                                                handleChange={handleChange}
                                            />
                                            <Dropdown
                                                label="State"
                                                options={stateOptions}
                                                name="state"
                                                innerLable
                                                value={
                                                    data.state
                                                        ? data.state
                                                        : ''
                                                }
                                                placeholder="Select State"
                                                fullWidth
                                            />
                                            <Dropdown
                                                label="Will Buy"
                                                options={timelineOptions}
                                                name="timeline"
                                                innerLable
                                                placeholder="When are you looking to buy?"
                                                fullWidth
                                            />
                                            <TextArea
                                                rows={4}
                                                fullWidth
                                                placeholder="Notes (Optional)"
                                                name="note"
                                                label="Notes"
                                                handleChange={handleChange}
                                            />
                                        </div>

                                        <div className="quote-button">
                                            <p>
                                                Have you had a novated
                                                lease before?
                                            </p>
                                            <OutlineButton
                                                handleOnclick={() =>
                                                    setleasebefore(true)
                                                }
                                                btnText="Yes"
                                                isActive={leasebefore}
                                                type="button"
                                                className={
                                                    leasebefore
                                                        ? 'active'
                                                        : ''
                                                }
                                                round
                                                sm
                                            />
                                            <OutlineButton
                                                handleOnclick={() =>
                                                    setleasebefore(false)
                                                }
                                                isActive={!leasebefore}
                                                btnText="No, this is the first time"
                                                type="button"
                                                className={
                                                    !leasebefore
                                                        ? 'active'
                                                        : ''
                                                }
                                                round
                                                sm
                                            />
                                            <div className="btn-style">
                                                <PrimaryButton
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    btnText={
                                                        isSubmitting
                                                            ? 'Submitting...'
                                                            : 'Request Quote'
                                                    }
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </section>
                        </Form>
                    );
                }}
            </Formik>
        </QuoteWrappper>
    );
};

const mapStateToProps = (state) => {
    return {
        makesOptions: state.vehicle.make_options,
        modelOptions: state.vehicle.model_options,
        bodyTypeOptions: state.vehicle.body_type_options,
        variantOptions: state.vehicle.variant_options,
    };
};

export const Quote = connect(mapStateToProps)(QuoteContainer);
