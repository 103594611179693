import styled from 'styled-components';
import { theme } from '../../theme';
export const Button = styled.button`
    border: none;
    color: white;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    padding: 0px 24px;
    transition-duration: 0.4s;
    cursor: pointer;
    font-family: ${theme.fonts.Inter_semibold};
    font-size: 16px;
    background-color: ${theme.colors.background_tint_light};
    color: ${(props) =>
        props.danger ? theme.colors.red : theme.colors.type_dark_heading};
    border: 1px solid transparent;

    @media (hover: hover) {
        :hover {
            background-color: ${(props) =>
                props.danger
                    ? theme.colors.red
                    : theme.colors.brand_primary_blue};
            color: white;
        }
    }
    ${(props) =>
        props.isActive &&
        `
            background-color: ${
                props.danger
                    ? theme.colors.red
                    : theme.colors.background_accent
            };
            color: ${
                props.danger
                    ? theme.colors.red
                    : theme.colors.type_dark_heading
            };
            border: 1px solid ${
                props.danger
                    ? theme.colors.red
                    : theme.colors.brand_primary_blue
            };
        `}
    border-radius:${(props) => (props.round ? '30px' : '5px')};
    ${theme.mediaQueriesMaxWidth.ipadpro} {
        min-width: ${(props) =>
            props.lg ? '200px' : props.sm ? '132px' : '270px'};
    }
    ${theme.mediaQueriesMaxWidth.sm} {
        margin: 0;
        min-width: ${(props) =>
            props.lg ? '270px' : props.sm ? '128px' : '128px'};
        height: ${(props) =>
            props.lg ? '60px' : props.sm ? '40px' : '40px'};
    }

    min-width: ${(props) => (props.lg ? '315px' : '128px')};
    height: ${(props) => (props.lg ? '60px' : '58px')};

    box-sizing: border-box;
    text-align: center;
`;
