import styled from 'styled-components';
import { theme } from '../../theme';

export const Box = styled.div`
	padding: 64px 0 0;
	position:relative;
	z-index:20;
	text-align: left;
	background: ${theme.colors.background_dark_blue};
	a {
		text-decoration: none;
	}
	.footer-logo {
		width: 150px;
	}
	.list-right {
		padding-right: 27px;
	}
	.up-style {
		margin-top:-10px;
		margin-left: auto;
	}
	.list-horizental {
		display:flex;
	}
	.list-unstyled {
		margin-bottom: 0;

		li:first-child {
			a {
				margin-top: 0;
			}
		}

		li:last-child {
			a {
				margin-bottom: 0;
			}
		}

		li.phone {
			display: flex;
			flex-wrap: wrap;
			margin-top: 40px;
			gap: 16px;
			font-family: ${theme.fonts.Inter_regular};
			color: ${theme.colors.white};

			.label-phone {
				flex: 0 0 100%;
				line-height: 150%;
			}

			a {
				margin-bottom: 0;
				font-size: 20px;
				line-height: 140%;
			}
		}

		li.social-media {
			display: flex;
			gap: 16px;
		}

		.sign-in-button {
			min-width: 101px;
			margin: 0;
			margin-top: 40px;
			border-radius: 9999px;
			padding: 16px 24px;
			font-size: 16px;
			line-height: 100%;
			font-family: ${theme.fonts.Inter_semibold};
			color: ${theme.colors.type_dark_heading};
			transition: all ease-out .3s;

			&:hover {
				background-color: ${theme.colors.brand_primary_blue};
				color: ${theme.colors.white};
				border-color: ${theme.colors.brand_primary_blue};
			}
		}
	}
	${theme.mediaQueriesMaxWidth.lg} {
		padding: 48px 0 0;

		.list-right {
			padding-right: 0;
		}

		.list-unstyled {
			margin-top: 48px;

			&.last-child-footer {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				max-height: 225px;

				li {
					display: flex;
					width: 100%;

					&:last-child {
						a {
							margin-top: 0;
							padding-left: 24px;
						}
					}
				}
			}

			&.mobile-footer {
				position: relative;
				width: 100%;
				margin-top: 8px;
				border-top: 1px solid rgba(255, 255, 255, 0.2);

				.phone {
					gap: 12px;
					margin-top: 32px;
				}

				.sign-in-button {
					position: absolute;
					top: 0;
					right: 0;
					margin-top: 32px;
					min-width: 88px;
					padding: 16px;
					font-size: 14px;
				}
			}
		}

		.first-child-class {
			.list-unstyled {
				margin-top: 0;
			}
		}
	}
	${theme.mediaQueriesMaxWidth.sm} {
		padding: 64px 0 0;

		.list-right {
			padding-right:0;
			margin-bottom:10px;
		}
		.list-horizental {
			display:block;
		}
		.up-style{
			position: relative;
			margin-top:30px;
		}
		.list-unstyled {
			padding-left: 0;
		}
   	}
	@media screen and (min-width: 1440px) {
		.container {
			max-width: 1440px;
		}
	}
`;

export const Hr = styled.div`
	border-top: 1px solid white;
	margin-top:80px;
	margin-bottom:80px;
	${theme.mediaQueriesMaxWidth.sm} {
		display:none;
	}
`;

export const Column = styled.div`
display: flex;
flex-direction: column;
text-align: left;
`;

export const FooterLink = styled.a`
position: relative;
display: inline-flex;
color: #fff;
margin-bottom: 32px;
font-size: 16px;
line-height: 100%;
font-family: ${theme.fonts.Inter_regular};

&.less-margin {
	margin-bottom: 24px;
}

&:before {
	content: "";
	transform-origin: 100% 50%;
	transform: scale3d(0, 1, 1);
	transition: transform .3s;
	position: absolute;
	width: 100%;
	height: 1px;
	background: #fff;
	bottom: -5px;
	left: 0;
	pointer-events: none;
}

&:hover {
	color: #fff;
	text-decoration: none;

	&:before {
		transform-origin: 0% 50%;
		transform: scaleZ(1);
	}
}
`;

export const Heading = styled.a`
position: relative;
display: inline-flex;
font-size: 18px;
line-height: 100%;
font-family: ${theme.fonts.Inter_regular};
color: #fff;
margin-top: 24px;
margin-bottom: 32px;

&:before {
	content: "";
	transform-origin: 100% 50%;
	transform: scale3d(0, 1, 1);
	transition: transform .3s;
	position: absolute;
	width: 100%;
	height: 1px;
	background: #fff;
	bottom: -5px;
	left: 0;
	pointer-events: none;
}

&:hover {
	color: #fff;
	text-decoration: none;

	&:before {
		transform-origin: 0% 50%;
		transform: scaleZ(1);
	}
}
`;

export const SocialMediaLinks = styled.a`
display: flex;
align-items: center;
justify-content: center;
width: 40px;
height: 40px;
margin-top: 40px;
border-radius: 50%;
padding: 10px;
background-color: #ffffff1a;
color: #fff;
transition: background-color .3s;

svg {
	width: 100%;
	height: 100%;
}

&:hover {
    background-color: #e91d28;
}
`;

export const FooterLegal = styled.div`
margin-top: 64px;
padding: 48px 0;
border-radius: 40px 40px 0 0;
background-color: ${theme.colors.white};

.signature {
	margin-top: 32px;
	text-align: left;
}

${theme.mediaQueriesMaxWidth.lg} {
	margin-top: 48px;
	padding: 24px 0;
	border-radius: 24px 24px 0 0;

	.list-unstyled {
		display: flex;
		flex-wrap: wrap;
		row-gap: 32px;
		column-gap: 20px;
		margin-top: 0;

		li {
			margin-bottom: 0;
		}

		.list-full-width-mobile {
			flex: 0 0 100%;
		}
	}
}
`;

export const FooterLegalContent = styled.p`
margin-bottom: 0;
font-size: 14px;
line-height: 140%;
font-family: ${theme.fonts.Inter_regular};

${theme.mediaQueriesMaxWidth.md} {
	text-align: left;
}
`;

export const FooterLegalLink = styled.a`
position: relative;
display: flex;
margin-bottom: 0;
font-size: 14px;
line-height: 140%;
font-family: ${theme.fonts.Inter_regular};
color: ${theme.colors.type_dark_heading};

&:before {
	content: "";
	transform-origin: 100% 50%;
	transform: scale3d(0, 1, 1);
	transition: transform .3s;
	position: absolute;
	width: 100%;
	height: 1px;
	background: ${theme.colors.type_dark_heading};
	bottom: -1px;
	left: 0;
	pointer-events: none;
}

&:hover {
	color: ${theme.colors.type_dark_heading};
	text-decoration: none;

	&:before {
		transform-origin: 0% 50%;
		transform: scaleZ(1);
	}
}
`;