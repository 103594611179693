import React, { useState } from 'react';
import { RangeSliderWrappper } from './RangeSliderStyled';
import { theme } from '../../../theme';
import { Range, getTrackBackground } from 'react-range';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { IS_LOADING } from '../../../actions/actionTypes';
import { connect } from 'react-redux';

export const RangeSliderContainer = ({
    headtext,
    current,
    isLoading,
    min,
    max,
    step,
    after = '',
    before = '',
    name,
    customHandle,
    handleFinalChange,
    options,
    overMax,
    className,
}) => {
    const [values, setvalues] = useState([current]);
    const { setFieldValue } = useFormikContext();
    const dispatch = useDispatch();
    const valuesOnChange = (values) => {
        if (!isLoading) dispatch({ type: IS_LOADING, payload: true });
        setvalues(values);
        setFieldValue(
            name,
            options ? options[values[0]].value : values[0] ?? ''
        );
        if (customHandle) {
            customHandle(values[0]);
        }
    };
    const onFinalChange = (values) => {
        dispatch({ type: IS_LOADING, payload: false });
        if (handleFinalChange) {
            handleFinalChange(values[0]);
        }
    };

    const showMarker = (index) => {
        // Array.from(Array(5).keys()).includes((index * step) / (max - min) * 4)
        if (
            Array.from(Array(5).keys()).includes(
                ((index * step) / (max - min)) * 5
            )
        ) {
            return true;
        } else if (
            Array.from(Array(5).keys()).includes(
                ((index * step) / (max - min)) * 4
            ) &&
            step === 1
        ) {
            return true;
        } else if (min + index * step === max) {
            return true;
        }

        return false;
    };
    return (
        <RangeSliderWrappper className={className}>
            <p>{headtext}</p>
            <div
                className="range-slider-container"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    margin: '',
                }}
            >
                <Range
                    name={name}
                    values={values}
                    step={step}
                    min={min}
                    max={max}
                    onChange={valuesOnChange}
                    onFinalChange={onFinalChange}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: '36px',
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    position: 'relative',
                                    height: '4px',
                                    width: '100%',
                                    background: getTrackBackground({
                                        values: values,
                                        colors: [
                                            theme.colors
                                                .brand_secondary_blue,
                                            '#FBFBFB',
                                        ],
                                        min: min,
                                        max: max,
                                    }),
                                    alignSelf: 'center',
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderMark={({ props, index }) => {
                        return (
                            <div
                                className="slider-mark"
                                {...props}
                                style={{
                                    ...props.style,
                                    height: '4px',
                                    width: '4px',
                                    background:
                                        index * step <=
                                        values[0] - (step + min)
                                            ? theme.colors
                                                  .brand_secondary_blue
                                            : showMarker(index)
                                            ? theme.colors
                                                  .background_tint_dark
                                            : 'transparent',
                                }}
                            ></div>
                        );
                    }}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '40px',
                                width: '40px',
                                borderRadius: '35px',
                                backgroundColor: `${theme.colors.brand_secondary_blue}`,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '0px 2px 6px #AAA',
                                outline: 'none',
                                cursor: 'pointer',
                            }}
                        ></div>
                    )}
                />
            </div>
            <h3>
                {before +
                    '' +
                    (options
                        ? options[values[0]].label.toLocaleString('en-US')
                        : values[0].toLocaleString('en-US')) +
                    ' ' +
                    after}{' '}
                {overMax && current === max ? ' Plus' : ''}
            </h3>
        </RangeSliderWrappper>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.vehicle.isLoading,
    };
};
export const RangeSlider = connect(mapStateToProps)(RangeSliderContainer);
