import styled from 'styled-components';
import { theme } from '../../theme';
export const QuoteWrappper = styled.div`
      
     .quote-section{
        position: relative;
        padding: 184px 140px;
        p{
         color:${theme.colors.type_body_text};
         font-size: 20px;
         line-height: 140%;
         font-family: ${theme.fonts.Inter_regular};
         padding: 0px 20px;
         }
         .backbutton {
            position: absolute;
            left: 175px;
            display: flex;
            align-items: center;
            gap: 8px;
            height: 40px;
            padding: 8px 16px;
            border-radius: 9999px;
            border: 1px solid ${theme.colors.background_tint_dark};
            background: ${theme.colors.background_tint_light};
            color: ${theme.colors.type_dark_heading};
            font-size: 14px;
            line-height: 100%;
            font-family: ${theme.fonts.Inter_regular};
            transition-duration: 0.4s;

            svg {
               path {
                  transition-duration: 0.4s;
               }
            }

            &:hover {
               background: ${theme.colors.brand_secondary_blue};
               color: ${theme.colors.white};
               border-color: ${theme.colors.brand_secondary_blue};
               
               svg {
                  path {
                     stroke: ${theme.colors.white};
                  }
               }
            }
         }
         justify-content: center;
         display: flex;
     }
     .quote-heading{
         margin-bottom: 24px;
         color:${theme.colors.type_dark_heading};
         font-size: 40px;
         line-height: 100%;
         font-family: ${theme.fonts.ITC_bold};
         padding: 0px 20px;
     }
     .quote-paragraph{
         margin-top: 40px;
         margin-bottom: 24px;
         padding: 0px 20px;
         color:${theme.colors.type_supporting_text} !important;
         font-size: 20px;
         line-height: 130%;
         font-family: ${theme.fonts.Inter_medium};
     }
     
     .form-style{
        padding: 20px 20px 0px 20px;
        p{
            color:${theme.colors.easiblue};
            font-size: 23px;
        }
        span{
           color: ${theme.colors.red};
           margin-left:5px; 
        }
      
         }
     
      }
     h2 {
      color:${theme.colors.easiblue};
      font-size: 26px;
      font-weight:bold;
     }
     
     .quote-button{
      display: flex;
      flex-wrap: wrap;
      margin-top: 19px;
      padding-left: 20px;
      padding-right: 20px;
      text-align: left;

      p {
         width: 100%;
         margin-bottom: 40px;
         padding: 0;
         text-align: left;
         color:${theme.colors.type_dark_heading};
         font-size: 24px;
         line-height: 140%;
         font-family: ${theme.fonts.Inter_semibold};
      }

      button[type="button"] {
         position: relative;
         margin: 0;
         width: calc(50% - 40px);
         height: 76px;
         border: 1px solid ${theme.colors.line_stroke_medium};
         border-radius: 9999px;
         padding: 0 32px 0 76px;
         font-size: 20px;
         line-height: 140%;
         font-family: ${theme.fonts.Inter_regular};
         text-align: left;

         &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 32px;
            width: 20px;
            height: 20px;
            background: transparent;
            border-radius: 100%;
            border: 1px solid grey;
            transform: translateY(-50%);
            transition-duration: 0.4s;
         }

         &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 38px;
            width: 8px;
            height: 8px;
            background: transparent;
            border-radius: 100%;
            border: 1px solid transparent;
            transform: translateY(-50%);
            transition-duration: 0.4s;
         }

         &.active {
            color: ${theme.colors.brand_primary_blue};
            border: 2px solid ${theme.colors.brand_primary_blue};
            
            &:before {
               border-color: ${theme.colors.white};
               background: ${theme.colors.brand_primary_blue};
            }

            &:after {
               background: ${theme.colors.white};
            }
         }

         &:hover, &:focus-visible {
            color: ${theme.colors.white};

            &:before {
               border-color: ${theme.colors.white};
            }

            &:after {
               background: ${theme.colors.white};
            }
         }

         &:nth-child(2) {
            margin-right: 40px;
         }
      }
         @media (max-width: 768px) {
         button[type="button"]{
         &:hover, &:focus-visible {
            color: ${theme.colors.type_dark_heading};

            &:before {
               border-color: ${theme.colors.white};
            }

            &:after {
               background: ${theme.colors.white};
            }
         }
      }
         }
       
      }
      .btn-style{
         width: 100%;
         margin-top:40px;

         button {
            margin: 0;
            border-radius: 9999px;
            background: ${theme.colors.brand_secondary_blue};
            border-color: ${theme.colors.brand_secondary_blue};
            font-size: 16px;
            font-family: ${theme.fonts.Inter_semibold};
            line-height: 100%;

            &:hover, &:focus-visible {
               background: ${theme.colors.brand_primary_blue};
               border-color: ${theme.colors.brand_primary_blue};
            }
         }
      }
      ${theme.mediaQueriesMaxWidth.xxxl}{
         .quote-button {
            p {
               margin-bottom: 24px;
               font-size: 20px;
               line-height: 120%;
            }

            button[type="button"] {
               width: 100%;
               height: 56px;
               padding: 0 20px 0 52px;

               &:nth-child(2) {
                  margin-bottom: 16px;
                  margin-right: 0;
               }

               &:before {
                  left: 20px;
               }

               &:after {
                  left: 26px;
               }
            }
         }
      }
      ${theme.mediaQueriesMaxWidth.xl} {
         .quote-heading{
            color:${theme.colors.easiblue};
            font-size: 33px;
            padding-right: 0px;
         }
         .quote-section{
            padding: 110px 15px 59px 15px;

            .backbutton {
               left: 145px;
            }
            
            p {
               padding: 0;
               text-align: left;
               font-size: 18px;
               line-height: 140%;
            }

            .quote-paragraph {
               padding: 0;
               margin-top: 80px;
               text-align: left;
            }

            .quote-heading {
               padding: 0;
               text-align: left;
               color:${theme.colors.type_dark_heading};
            }
         }
      
         .form-style {
            padding: 16px 0;

            p  {
               color:${theme.colors.type_body_text};
               font-size: 20px;
               font-family: ${theme.fonts.Inter_regular};
            }
         }
         
         .quote-button {
            margin-top: 0;
            padding-left: 0;
            padding-right: 0;
         }
      }


     ${theme.mediaQueriesMaxWidth.md}{
         .quote-section {
            .backbutton {
               left: 15px;
            }
         }
     }
   
`;
