import { navigate } from '@reach/router';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player/lazy';
import { PrimaryButton } from '../PrimaryButton';
import Accordion from '../Accordion/Accordion';
import { faqs } from './faqs.js';
import {
    FAQSection,
    PlayerStyles,
    PlayerWrapper,
    PlayIcon,
    ThankYouPageWrapper,
    ThankYouSection,
    VideoSection,
} from './styled';
import { theme } from '../../theme.js';

export const ThankYou = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [openedIndex, setOpenedIndex] = useState(false);

    const renderFAQS = () => {
        return faqs.map((faq, i) => (
            <Accordion
                key={i}
                head={faq.title}
                body={faq.body}
                opened={openedIndex === i}
                handleOnClick={() => {
                    if (openedIndex === i) {
                        setOpenedIndex(false);
                    } else {
                        setOpenedIndex(i);
                    }
                }}
            />
        ));
    };

    return (
        <ThankYouPageWrapper>
            <Image
                className="small-cloud"
                alt="icons"
                src="/Images/Vector3.png"
            />
            <Image
                className="big-cloud"
                alt="icon"
                src="/Images/Vector.png"
            />
            <Image
                className="half-cloud"
                alt="icons"
                src="/Images/Vector2.png"
            />
            <Image
                className="dollar-icon1"
                alt="icon"
                width="48"
                height="48"
                src="/Images/coin-new.svg"
            />
            <Image
                className="dollar-icon2"
                alt="icon"
                width="48"
                height="48"
                src="/Images/coin-new.svg"
            />

            <ThankYouSection className="container">
                <h1>Thank you</h1>
                <p>
                    Thank you for enquiring about a novated car lease.
                    <br /> We will be in touch with you very soon with your
                    request.
                </p>
                <PrimaryButton
                    type="button"
                    btnText="Return Home"
                    handleOnclick={() => navigate('/')}
                />
            </ThankYouSection>
            <VideoSection>
                <PlayerWrapper>
                    <ReactPlayer
                        url={
                            'https://vimeo.com/362459242?embedded=true&source=video_title&owner=99070819'
                        }
                        light={`/Images/video_thumbnail.jpeg`}
                        height="100%"
                        width="100%"
                        style={PlayerStyles}
                        playIcon={PlayIcon}
                        playing={true}
                        onClick={() => setIsVideoPlaying(true)}
                    />
                    {isVideoPlaying ? null : (
                        <h3 className="d-none d-lg-block">
                            An Easi Novated Lease Can Save You Thousands.
                        </h3>
                    )}
                    {isVideoPlaying ? null : (
                        <div className="logo-wrapper">
                            <Image
                                src="/Images/logo-reskinned.svg"
                                alt="logo"
                                width="80"
                                height="22"
                            />
                        </div>
                    )}
                    {isVideoPlaying ? null : (
                        <div className="button-wrapper d-none d-lg-block">
                            <PrimaryButton
                                type="button"
                                btnText="Watch Video"
                                handleOnclick={() =>
                                    window.open(
                                        'https://vimeo.com/362459242?embedded=true&source=video_title&owner=99070819'
                                    )
                                }
                            />
                        </div>
                    )}
                </PlayerWrapper>
                <h3 className="d-block d-lg-none">
                    An Easi Novated Lease Can Save You Thousands.
                </h3>
                <div className="button-wrapper d-block d-lg-none">
                    <PrimaryButton
                        type="button"
                        btnText="Watch Video"
                        handleOnclick={() =>
                            window.open(
                                'https://vimeo.com/362459242?embedded=true&source=video_title&owner=99070819'
                            )
                        }
                    />
                </div>
            </VideoSection>
            <FAQSection className="container">
                <Row>
                    <Col lg={4} className="text-start">
                        <h1>Your questions answered</h1>
                        <p className="faq-support text-start">
                            Can’t find an answer to your question?
                        </p>
                        <PrimaryButton
                            type="button"
                            btnText="Chat with easibot"
                            md
                            className="faq-support-button"
                            handleOnclick={() =>
                                navigate(
                                    'https://www.easifleet.com.au/frequent-questions/'
                                )
                            }
                        />
                    </Col>
                    <Col lg={7} className="offset-lg-1">
                        <div className="faq-container">{renderFAQS()}</div>
                        <PrimaryButton
                            type="button"
                            btnText="View all FAQs"
                            md
                            bg={theme.colors.background_accent}
                            className="faq-button"
                            handleOnclick={() =>
                                navigate(
                                    'https://www.easifleet.com.au/frequent-questions/'
                                )
                            }
                        />
                    </Col>
                </Row>
            </FAQSection>
        </ThankYouPageWrapper>
    );
};
