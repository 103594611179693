import styled from 'styled-components';
import {theme} from '../theme';

export const AppWrapper = styled.div`
        position:relative;
        transition: all 0.4s ease;
        left: 0px;
        ${ props => !props.menuCollapse && `
            transition: all 0.4s ease;
        `}
        ${theme.mediaQueriesMaxWidth.sm} {
            ${ props => !props.menuCollapse && `
                transition: all 0.4s ease;
            `}

        }

`